:root {
  --font-family: system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height: 1.5;
  --font-weight: 400;
  --font-size: 16px;
  --border-radius: .25rem;
  --border-width: 1px;
  --outline-width: 3px;
  --spacing: 1rem;
  --typography-spacing-vertical: 1.5rem;
  --block-spacing-vertical: calc(var(--spacing) * 2);
  --block-spacing-horizontal: var(--spacing);
  --grid-spacing-vertical: 0;
  --grid-spacing-horizontal: var(--spacing);
  --form-element-spacing-vertical: .75rem;
  --form-element-spacing-horizontal: 1rem;
  --nav-element-spacing-vertical: 1rem;
  --nav-element-spacing-horizontal: .5rem;
  --nav-link-spacing-vertical: .5rem;
  --nav-link-spacing-horizontal: .5rem;
  --form-label-font-weight: var(--font-weight);
  --transition: .2s ease-in-out;
}

@media (min-width: 576px) {
  :root {
    --font-size: 17px;
  }
}

@media (min-width: 768px) {
  :root {
    --font-size: 18px;
  }
}

@media (min-width: 992px) {
  :root {
    --font-size: 19px;
  }
}

@media (min-width: 1200px) {
  :root {
    --font-size: 20px;
  }
}

@media (min-width: 576px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 2.5);
  }
}

@media (min-width: 768px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 3);
  }
}

@media (min-width: 992px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 3.5);
  }
}

@media (min-width: 1200px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 4);
  }
}

@media (min-width: 576px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.25);
  }
}

@media (min-width: 768px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.5);
  }
}

@media (min-width: 992px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.75);
  }
}

@media (min-width: 1200px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 2);
  }
}

dialog > article {
  --block-spacing-vertical: calc(var(--spacing) * 2);
  --block-spacing-horizontal: var(--spacing);
}

@media (min-width: 576px) {
  dialog > article {
    --block-spacing-vertical: calc(var(--spacing) * 2.5);
    --block-spacing-horizontal: calc(var(--spacing) * 1.25);
  }
}

@media (min-width: 768px) {
  dialog > article {
    --block-spacing-vertical: calc(var(--spacing) * 3);
    --block-spacing-horizontal: calc(var(--spacing) * 1.5);
  }
}

a {
  --text-decoration: none;
}

a.contrast, a.secondary {
  --text-decoration: underline;
}

small {
  --font-size: .875em;
}

h1, h2, h3, h4, h5, h6 {
  --font-weight: 700;
}

h1 {
  --font-size: 2rem;
  --typography-spacing-vertical: 3rem;
}

h2 {
  --font-size: 1.75rem;
  --typography-spacing-vertical: 2.625rem;
}

h3 {
  --font-size: 1.5rem;
  --typography-spacing-vertical: 2.25rem;
}

h4 {
  --font-size: 1.25rem;
  --typography-spacing-vertical: 1.874rem;
}

h5 {
  --font-size: 1.125rem;
  --typography-spacing-vertical: 1.6875rem;
}

[type="checkbox"], [type="radio"] {
  --border-width: 2px;
}

[type="checkbox"][role="switch"], tfoot td, tfoot th, thead td, thead th {
  --border-width: 3px;
}

:not(thead):not(tfoot) > * > td {
  --font-size: .875em;
}

code, kbd, pre, samp {
  --font-family: "Menlo", "Consolas", "Roboto Mono", "Ubuntu Monospace", "Noto Mono", "Oxygen Mono", "Liberation Mono", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

kbd {
  --font-weight: bolder;
}

:root:not([data-theme="dark"]), [data-theme="light"] {
  color-scheme: light;
  --background-color: #fff;
  --color: #415462;
  --h1-color: #1b2832;
  --h2-color: #24333e;
  --h3-color: #2c3d49;
  --h4-color: #374956;
  --h5-color: #415462;
  --h6-color: #4d606d;
  --muted-color: #73828c;
  --muted-border-color: #edf0f3;
  --primary: #1095c1;
  --primary-hover: #08769b;
  --primary-focus: #1095c120;
  --primary-inverse: #fff;
  --secondary: #596b78;
  --secondary-hover: #415462;
  --secondary-focus: #596b7820;
  --secondary-inverse: #fff;
  --contrast: #1b2832;
  --contrast-hover: #000;
  --contrast-focus: #596b7820;
  --contrast-inverse: #fff;
  --mark-background-color: #fff2ca;
  --mark-color: #543a26;
  --ins-color: #388e3c;
  --del-color: #c62828;
  --blockquote-border-color: var(--muted-border-color);
  --blockquote-footer-color: var(--muted-color);
  --button-box-shadow: 0 0 0 #0000;
  --button-hover-box-shadow: 0 0 0 #0000;
  --form-element-background-color: transparent;
  --form-element-border-color: #a2afb9;
  --form-element-color: var(--color);
  --form-element-placeholder-color: var(--muted-color);
  --form-element-active-background-color: transparent;
  --form-element-active-border-color: var(--primary);
  --form-element-focus-color: var(--primary-focus);
  --form-element-disabled-background-color: #d5dce2;
  --form-element-disabled-border-color: #a2afb9;
  --form-element-disabled-opacity: .5;
  --form-element-invalid-border-color: #c62828;
  --form-element-invalid-active-border-color: #d32f2f;
  --form-element-invalid-focus-color: #d32f2f20;
  --form-element-valid-border-color: #388e3c;
  --form-element-valid-active-border-color: #43a047;
  --form-element-valid-focus-color: #43a04720;
  --switch-background-color: #bbc6ce;
  --switch-color: var(--primary-inverse);
  --switch-checked-background-color: var(--primary);
  --range-border-color: #d5dce2;
  --range-active-border-color: #bbc6ce;
  --range-thumb-border-color: var(--background-color);
  --range-thumb-color: var(--secondary);
  --range-thumb-hover-color: var(--secondary-hover);
  --range-thumb-active-color: var(--primary);
  --table-border-color: var(--muted-border-color);
  --table-row-stripped-background-color: #f6f8f9;
  --code-background-color: #edf0f3;
  --code-color: var(--muted-color);
  --code-kbd-background-color: var(--contrast);
  --code-kbd-color: var(--contrast-inverse);
  --code-tag-color: #b34d80;
  --code-property-color: #3d888f;
  --code-value-color: #986;
  --code-comment-color: #a2afb9;
  --accordion-border-color: var(--muted-border-color);
  --accordion-close-summary-color: var(--color);
  --accordion-open-summary-color: var(--muted-color);
  --card-background-color: var(--background-color);
  --card-border-color: var(--muted-border-color);
  --card-box-shadow: .0145rem .029rem .174rem #1b283204, .0335rem .067rem .402rem #1b283206, .0625rem .125rem .75rem #1b283208, .1125rem .225rem 1.35rem #1b283209, .2085rem .417rem 2.502rem #1b28320b, .5rem 1rem 6rem #1b28320f, 0 0 0 .0625rem #1b283204;
  --card-sectionning-background-color: #fbfbfc;
  --dropdown-background-color: #fbfbfc;
  --dropdown-border-color: #e1e6eb;
  --dropdown-box-shadow: var(--card-box-shadow);
  --dropdown-color: var(--color);
  --dropdown-hover-background-color: #edf0f3;
  --modal-overlay-background-color: #d5dce2cc;
  --progress-background-color: #d5dce2;
  --progress-color: var(--primary);
  --loading-spinner-opacity: .5;
  --tooltip-background-color: var(--contrast);
  --tooltip-color: var(--contrast-inverse);
  --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(255, 255, 255, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(255, 255, 255, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(115, 130, 140, 0.999)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(198, 40, 40, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(65, 84, 98, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(56, 142, 60, 0.999)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme="light"]) {
    color-scheme: dark;
    --background-color: #11191f;
    --color: #bbc6ce;
    --h1-color: #edf0f3;
    --h2-color: #e1e6eb;
    --h3-color: #d5dce2;
    --h4-color: #c8d1d8;
    --h5-color: #bbc6ce;
    --h6-color: #afbbc4;
    --muted-color: #73828c;
    --muted-border-color: #1f2d38;
    --primary: #1095c1;
    --primary-hover: #1ab3e6;
    --primary-focus: #1095c140;
    --primary-inverse: #fff;
    --secondary: #596b78;
    --secondary-hover: #73828c;
    --secondary-focus: #73828c40;
    --secondary-inverse: #fff;
    --contrast: #edf0f3;
    --contrast-hover: #fff;
    --contrast-focus: #73828c40;
    --contrast-inverse: #000;
    --mark-background-color: #d1c284;
    --mark-color: #11191f;
    --ins-color: #388e3c;
    --del-color: #c62828;
    --blockquote-border-color: var(--muted-border-color);
    --blockquote-footer-color: var(--muted-color);
    --button-box-shadow: 0 0 0 #0000;
    --button-hover-box-shadow: 0 0 0 #0000;
    --form-element-background-color: #11191f;
    --form-element-border-color: #374956;
    --form-element-color: var(--color);
    --form-element-placeholder-color: var(--muted-color);
    --form-element-active-background-color: var(--form-element-background-color);
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --form-element-disabled-background-color: #2c3d49;
    --form-element-disabled-border-color: #415462;
    --form-element-disabled-opacity: .5;
    --form-element-invalid-border-color: #b71c1c;
    --form-element-invalid-active-border-color: #c62828;
    --form-element-invalid-focus-color: #c6282840;
    --form-element-valid-border-color: #2e7d32;
    --form-element-valid-active-border-color: #388e3c;
    --form-element-valid-focus-color: #388e3c40;
    --switch-background-color: #374956;
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
    --range-border-color: #24333e;
    --range-active-border-color: #2c3d49;
    --range-thumb-border-color: var(--background-color);
    --range-thumb-color: var(--secondary);
    --range-thumb-hover-color: var(--secondary-hover);
    --range-thumb-active-color: var(--primary);
    --table-border-color: var(--muted-border-color);
    --table-row-stripped-background-color: #73828c0d;
    --code-background-color: #18232c;
    --code-color: var(--muted-color);
    --code-kbd-background-color: var(--contrast);
    --code-kbd-color: var(--contrast-inverse);
    --code-tag-color: #a65980;
    --code-property-color: #599fa6;
    --code-value-color: #8c8473;
    --code-comment-color: #4d606d;
    --accordion-border-color: var(--muted-border-color);
    --accordion-active-summary-color: var(--primary);
    --accordion-close-summary-color: var(--color);
    --accordion-open-summary-color: var(--muted-color);
    --card-background-color: #141e26;
    --card-border-color: var(--card-background-color);
    --card-box-shadow: .0145rem .029rem .174rem #00000004, .0335rem .067rem .402rem #00000006, .0625rem .125rem .75rem #00000008, .1125rem .225rem 1.35rem #00000009, .2085rem .417rem 2.502rem #0000000b, .5rem 1rem 6rem #0000000f, 0 0 0 .0625rem #00000004;
    --card-sectionning-background-color: #18232c;
    --dropdown-background-color: #1b2832;
    --dropdown-border-color: #24333e;
    --dropdown-box-shadow: var(--card-box-shadow);
    --dropdown-color: var(--color);
    --dropdown-hover-background-color: #24333ebf;
    --modal-overlay-background-color: #24333ee6;
    --progress-background-color: #24333e;
    --progress-color: var(--primary);
    --loading-spinner-opacity: .5;
    --tooltip-background-color: var(--contrast);
    --tooltip-color: var(--contrast-inverse);
    --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(162, 175, 185, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(255, 255, 255, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(0, 0, 0, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(115, 130, 140, 0.999)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
    --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(162, 175, 185, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
    --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(183, 28, 28, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
    --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(162, 175, 185, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
    --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(162, 175, 185, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(46, 125, 50, 0.999)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  }
}

[data-theme="dark"] {
  color-scheme: dark;
  --background-color: #11191f;
  --color: #bbc6ce;
  --h1-color: #edf0f3;
  --h2-color: #e1e6eb;
  --h3-color: #d5dce2;
  --h4-color: #c8d1d8;
  --h5-color: #bbc6ce;
  --h6-color: #afbbc4;
  --muted-color: #73828c;
  --muted-border-color: #1f2d38;
  --primary: #1095c1;
  --primary-hover: #1ab3e6;
  --primary-focus: #1095c140;
  --primary-inverse: #fff;
  --secondary: #596b78;
  --secondary-hover: #73828c;
  --secondary-focus: #73828c40;
  --secondary-inverse: #fff;
  --contrast: #edf0f3;
  --contrast-hover: #fff;
  --contrast-focus: #73828c40;
  --contrast-inverse: #000;
  --mark-background-color: #d1c284;
  --mark-color: #11191f;
  --ins-color: #388e3c;
  --del-color: #c62828;
  --blockquote-border-color: var(--muted-border-color);
  --blockquote-footer-color: var(--muted-color);
  --button-box-shadow: 0 0 0 #0000;
  --button-hover-box-shadow: 0 0 0 #0000;
  --form-element-background-color: #11191f;
  --form-element-border-color: #374956;
  --form-element-color: var(--color);
  --form-element-placeholder-color: var(--muted-color);
  --form-element-active-background-color: var(--form-element-background-color);
  --form-element-active-border-color: var(--primary);
  --form-element-focus-color: var(--primary-focus);
  --form-element-disabled-background-color: #2c3d49;
  --form-element-disabled-border-color: #415462;
  --form-element-disabled-opacity: .5;
  --form-element-invalid-border-color: #b71c1c;
  --form-element-invalid-active-border-color: #c62828;
  --form-element-invalid-focus-color: #c6282840;
  --form-element-valid-border-color: #2e7d32;
  --form-element-valid-active-border-color: #388e3c;
  --form-element-valid-focus-color: #388e3c40;
  --switch-background-color: #374956;
  --switch-color: var(--primary-inverse);
  --switch-checked-background-color: var(--primary);
  --range-border-color: #24333e;
  --range-active-border-color: #2c3d49;
  --range-thumb-border-color: var(--background-color);
  --range-thumb-color: var(--secondary);
  --range-thumb-hover-color: var(--secondary-hover);
  --range-thumb-active-color: var(--primary);
  --table-border-color: var(--muted-border-color);
  --table-row-stripped-background-color: #73828c0d;
  --code-background-color: #18232c;
  --code-color: var(--muted-color);
  --code-kbd-background-color: var(--contrast);
  --code-kbd-color: var(--contrast-inverse);
  --code-tag-color: #a65980;
  --code-property-color: #599fa6;
  --code-value-color: #8c8473;
  --code-comment-color: #4d606d;
  --accordion-border-color: var(--muted-border-color);
  --accordion-active-summary-color: var(--primary);
  --accordion-close-summary-color: var(--color);
  --accordion-open-summary-color: var(--muted-color);
  --card-background-color: #141e26;
  --card-border-color: var(--card-background-color);
  --card-box-shadow: .0145rem .029rem .174rem #00000004, .0335rem .067rem .402rem #00000006, .0625rem .125rem .75rem #00000008, .1125rem .225rem 1.35rem #00000009, .2085rem .417rem 2.502rem #0000000b, .5rem 1rem 6rem #0000000f, 0 0 0 .0625rem #00000004;
  --card-sectionning-background-color: #18232c;
  --dropdown-background-color: #1b2832;
  --dropdown-border-color: #24333e;
  --dropdown-box-shadow: var(--card-box-shadow);
  --dropdown-color: var(--color);
  --dropdown-hover-background-color: #24333ebf;
  --modal-overlay-background-color: #24333ee6;
  --progress-background-color: #24333e;
  --progress-color: var(--primary);
  --loading-spinner-opacity: .5;
  --tooltip-background-color: var(--contrast);
  --tooltip-color: var(--contrast-inverse);
  --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(162, 175, 185, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(255, 255, 255, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(0, 0, 0, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(115, 130, 140, 0.999)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(162, 175, 185, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(183, 28, 28, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(162, 175, 185, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(162, 175, 185, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(46, 125, 50, 0.999)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}

*, :after, :before {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:after, :before {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  background-color: var(--background-color);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: var(--font-family);
  overflow-wrap: break-word;
  cursor: default;
  tab-size: 4;
}

main {
  display: block;
}

body {
  width: 100%;
  margin: 0;
}

body > footer, body > header, body > main {
  width: 100%;
  padding: var(--block-spacing-vertical) 0;
  margin-left: auto;
  margin-right: auto;
}

.container, .container-fluid {
  width: 100%;
  padding-right: var(--spacing);
  padding-left: var(--spacing);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 510px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 920px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1130px;
  }
}

section {
  margin-bottom: var(--block-spacing-vertical);
}

.grid {
  grid-column-gap: var(--grid-spacing-horizontal);
  grid-row-gap: var(--grid-spacing-vertical);
  grid-template-columns: 1fr;
  margin: 0;
  display: grid;
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
  }
}

.grid > * {
  min-width: 0;
}

figure {
  margin: 0;
  padding: 0;
  display: block;
  overflow-x: auto;
}

figure figcaption {
  padding: calc(var(--spacing) * .5) 0;
  color: var(--muted-color);
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

address, blockquote, dl, figure, form, ol, p, pre, table, ul {
  margin-top: 0;
  margin-bottom: var(--typography-spacing-vertical);
  color: var(--color);
  font-style: normal;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
}

[role="link"], a {
  --color: var(--primary);
  --background-color: transparent;
  background-color: var(--background-color);
  color: var(--color);
  -webkit-text-decoration: var(--text-decoration);
  -webkit-text-decoration: var(--text-decoration);
  text-decoration: var(--text-decoration);
  transition: background-color var(--transition), color var(--transition), box-shadow var(--transition), -webkit-text-decoration var(--transition);
  transition: background-color var(--transition), color var(--transition), text-decoration var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), color var(--transition), text-decoration var(--transition), box-shadow var(--transition), -webkit-text-decoration var(--transition);
  outline: 0;
}

[role="link"]:-webkit-any([aria-current], :hover, :active, :focus), a:-webkit-any([aria-current], :hover, :active, :focus) {
  --color: var(--primary-hover);
  --text-decoration: underline;
}

[role="link"]:is([aria-current], :hover, :active, :focus), a:is([aria-current], :hover, :active, :focus) {
  --color: var(--primary-hover);
  --text-decoration: underline;
}

[role="link"]:focus, a:focus {
  --background-color: var(--primary-focus);
}

[role="link"].secondary, a.secondary {
  --color: var(--secondary);
}

[role="link"].secondary:-webkit-any([aria-current], :hover, :active, :focus), a.secondary:-webkit-any([aria-current], :hover, :active, :focus) {
  --color: var(--secondary-hover);
}

[role="link"].secondary:is([aria-current], :hover, :active, :focus), a.secondary:is([aria-current], :hover, :active, :focus) {
  --color: var(--secondary-hover);
}

[role="link"].secondary:focus, a.secondary:focus {
  --background-color: var(--secondary-focus);
}

[role="link"].contrast, a.contrast {
  --color: var(--contrast);
}

[role="link"].contrast:-webkit-any([aria-current], :hover, :active, :focus), a.contrast:-webkit-any([aria-current], :hover, :active, :focus) {
  --color: var(--contrast-hover);
}

[role="link"].contrast:is([aria-current], :hover, :active, :focus), a.contrast:is([aria-current], :hover, :active, :focus) {
  --color: var(--contrast-hover);
}

[role="link"].contrast:focus, a.contrast:focus {
  --background-color: var(--contrast-focus);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--typography-spacing-vertical);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  font-family: var(--font-family);
}

h1 {
  --color: var(--h1-color);
}

h2 {
  --color: var(--h2-color);
}

h3 {
  --color: var(--h3-color);
}

h4 {
  --color: var(--h4-color);
}

h5 {
  --color: var(--h5-color);
}

h6 {
  --color: var(--h6-color);
}

:where(address, blockquote, dl, figure, form, ol, p, pre, table, ul) ~ :-webkit-any(h1, h2, h3, h4, h5, h6) {
  margin-top: var(--typography-spacing-vertical);
}

:where(address, blockquote, dl, figure, form, ol, p, pre, table, ul) ~ :is(h1, h2, h3, h4, h5, h6) {
  margin-top: var(--typography-spacing-vertical);
}

.headings, hgroup {
  margin-bottom: var(--typography-spacing-vertical);
}

.headings > *, hgroup > * {
  margin-bottom: 0;
}

.headings > :last-child, hgroup > :last-child {
  --color: var(--muted-color);
  --font-weight: unset;
  font-family: unset;
  font-size: 1rem;
}

p {
  margin-bottom: var(--typography-spacing-vertical);
}

small {
  font-size: var(--font-size);
}

:where(dl, ol, ul) {
  padding-right: 0;
  padding-left: var(--spacing);
  -webkit-padding-start: var(--spacing);
  -webkit-padding-end: 0;
  padding-inline-start: var(--spacing);
  padding-inline-end: 0;
}

:where(dl, ol, ul) li {
  margin-bottom: calc(var(--typography-spacing-vertical) * .25);
}

:where(dl, ol, ul) :-webkit-any(dl, ol, ul) {
  margin: 0;
  margin-top: calc(var(--typography-spacing-vertical) * .25);
}

:where(dl, ol, ul) :is(dl, ol, ul) {
  margin: 0;
  margin-top: calc(var(--typography-spacing-vertical) * .25);
}

ul li {
  list-style: square;
}

mark {
  background-color: var(--mark-background-color);
  color: var(--mark-color);
  vertical-align: baseline;
  padding: .125rem .25rem;
}

blockquote {
  margin: var(--typography-spacing-vertical) 0;
  padding: var(--spacing);
  border-right: none;
  border-left: .25rem solid var(--blockquote-border-color);
  -webkit-border-start: .25rem solid var(--blockquote-border-color);
  border-inline-start: .25rem solid var(--blockquote-border-color);
  -webkit-border-end: none;
  border-inline-end: none;
  display: block;
}

blockquote footer {
  margin-top: calc(var(--typography-spacing-vertical) * .5);
  color: var(--blockquote-footer-color);
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

ins {
  color: var(--ins-color);
  text-decoration: none;
}

del {
  color: var(--del-color);
}

::selection {
  background-color: var(--primary-focus);
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

:where(iframe) {
  border-style: none;
}

img {
  max-width: 100%;
  height: auto;
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

button {
  text-transform: none;
  margin: 0;
  font-family: inherit;
  overflow: visible;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

button {
  width: 100%;
  margin-bottom: var(--spacing);
  display: block;
}

[role="button"] {
  text-decoration: none;
  display: inline-block;
}

[role="button"], button, input[type="button"], input[type="reset"], input[type="submit"] {
  --background-color: var(--primary);
  --border-color: var(--primary);
  --color: var(--primary-inverse);
  --box-shadow: var(--button-box-shadow, 0 0 0 #0000);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
}

[role="button"]:-webkit-any([aria-current], :hover, :active, :focus), button:-webkit-any([aria-current], :hover, :active, :focus), input[type="button"]:-webkit-any([aria-current], :hover, :active, :focus), input[type="reset"]:-webkit-any([aria-current], :hover, :active, :focus), input[type="submit"]:-webkit-any([aria-current], :hover, :active, :focus) {
  --background-color: var(--primary-hover);
  --border-color: var(--primary-hover);
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000);
  --color: var(--primary-inverse);
}

[role="button"]:is([aria-current], :hover, :active, :focus), button:is([aria-current], :hover, :active, :focus), input[type="button"]:is([aria-current], :hover, :active, :focus), input[type="reset"]:is([aria-current], :hover, :active, :focus), input[type="submit"]:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--primary-hover);
  --border-color: var(--primary-hover);
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000);
  --color: var(--primary-inverse);
}

[role="button"]:focus, button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--primary-focus);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).secondary, input[type="reset"] {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  cursor: pointer;
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary, input[type="reset"] {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  cursor: pointer;
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).secondary:-webkit-any([aria-current], :hover, :active, :focus), input[type="reset"]:-webkit-any([aria-current], :hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
  --color: var(--secondary-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary:is([aria-current], :hover, :active, :focus), input[type="reset"]:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
  --color: var(--secondary-inverse);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).secondary:focus, input[type="reset"]:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--secondary-focus);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary:focus, input[type="reset"]:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--secondary-focus);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).contrast {
  --background-color: var(--contrast);
  --border-color: var(--contrast);
  --color: var(--contrast-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast {
  --background-color: var(--contrast);
  --border-color: var(--contrast);
  --color: var(--contrast-inverse);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).contrast:-webkit-any([aria-current], :hover, :active, :focus) {
  --background-color: var(--contrast-hover);
  --border-color: var(--contrast-hover);
  --color: var(--contrast-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--contrast-hover);
  --border-color: var(--contrast-hover);
  --color: var(--contrast-inverse);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).contrast:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--contrast-focus);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--contrast-focus);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).outline, input[type="reset"].outline {
  --background-color: transparent;
  --color: var(--primary);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline, input[type="reset"].outline {
  --background-color: transparent;
  --color: var(--primary);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).outline:-webkit-any([aria-current], :hover, :active, :focus), input[type="reset"].outline:-webkit-any([aria-current], :hover, :active, :focus) {
  --background-color: transparent;
  --color: var(--primary-hover);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline:is([aria-current], :hover, :active, :focus), input[type="reset"].outline:is([aria-current], :hover, :active, :focus) {
  --background-color: transparent;
  --color: var(--primary-hover);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary, input[type="reset"].outline {
  --color: var(--secondary);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary, input[type="reset"].outline {
  --color: var(--secondary);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary:-webkit-any([aria-current], :hover, :active, :focus), input[type="reset"].outline:-webkit-any([aria-current], :hover, :active, :focus) {
  --color: var(--secondary-hover);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary:is([aria-current], :hover, :active, :focus), input[type="reset"].outline:is([aria-current], :hover, :active, :focus) {
  --color: var(--secondary-hover);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).outline.contrast {
  --color: var(--contrast);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.contrast {
  --color: var(--contrast);
}

:-webkit-any(button, input[type="submit"], input[type="button"], [role="button"]).outline.contrast:-webkit-any([aria-current], :hover, :active, :focus) {
  --color: var(--contrast-hover);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.contrast:is([aria-current], :hover, :active, :focus) {
  --color: var(--contrast-hover);
}

:where(button, [type="submit"], [type="button"], [type="reset"], [role="button"])[disabled], :where(fieldset[disabled]) :-webkit-any(button, [type="submit"], [type="button"], [type="reset"], [role="button"]), a[role="button"]:not([href]) {
  opacity: .5;
  pointer-events: none;
}

:where(button, [type="submit"], [type="button"], [type="reset"], [role="button"])[disabled], :where(fieldset[disabled]) :is(button, [type="submit"], [type="button"], [type="reset"], [role="button"]), a[role="button"]:not([href]) {
  opacity: .5;
  pointer-events: none;
}

input, optgroup, select, textarea {
  font-size: 1rem;
  line-height: var(--line-height);
  letter-spacing: inherit;
  margin: 0;
  font-family: inherit;
}

input {
  overflow: visible;
}

select {
  text-transform: none;
}

legend {
  max-width: 100%;
  color: inherit;
  white-space: normal;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  padding: 0;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 0;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-ms-expand {
  display: none;
}

[type="file"], [type="range"] {
  border-width: 0;
  padding: 0;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]) {
  height: calc(1rem * var(--line-height)  + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
}

fieldset {
  margin: 0;
  margin-bottom: var(--spacing);
  border: 0;
  padding: 0;
}

fieldset legend, label {
  margin-bottom: calc(var(--spacing) * .25);
  font-weight: var(--form-label-font-weight, var(--font-weight));
  display: block;
}

input:not([type="checkbox"]):not([type="radio"]), select, textarea {
  width: 100%;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]), select, textarea {
  -webkit-appearance: none;
  appearance: none;
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
}

input, select, textarea {
  --background-color: var(--form-element-background-color);
  --border-color: var(--form-element-border-color);
  --color: var(--form-element-color);
  --box-shadow: none;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
}

:where(select, textarea):-webkit-any(:active, :focus), input:not([type="submit"]):not([type="button"]):not([type="reset"]):not([type="checkbox"]):not([type="radio"]):not([readonly]):-webkit-any(:active, :focus) {
  --background-color: var(--form-element-active-background-color);
}

:where(select, textarea):is(:active, :focus), input:not([type="submit"]):not([type="button"]):not([type="reset"]):not([type="checkbox"]):not([type="radio"]):not([readonly]):is(:active, :focus) {
  --background-color: var(--form-element-active-background-color);
}

:where(select, textarea):-webkit-any(:active, :focus), input:not([type="submit"]):not([type="button"]):not([type="reset"]):not([role="switch"]):not([readonly]):-webkit-any(:active, :focus) {
  --border-color: var(--form-element-active-border-color);
}

:where(select, textarea):is(:active, :focus), input:not([type="submit"]):not([type="button"]):not([type="reset"]):not([role="switch"]):not([readonly]):is(:active, :focus) {
  --border-color: var(--form-element-active-border-color);
}

input:not([type="submit"]):not([type="button"]):not([type="reset"]):not([type="range"]):not([type="file"]):not([readonly]):focus, select:focus, textarea:focus {
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

:where(fieldset[disabled]) :-webkit-any(input:not([type="submit"]):not([type="button"]):not([type="reset"]), select, textarea), input:not([type="submit"]):not([type="button"]):not([type="reset"])[disabled], select[disabled], textarea[disabled] {
  --background-color: var(--form-element-disabled-background-color);
  --border-color: var(--form-element-disabled-border-color);
  opacity: var(--form-element-disabled-opacity);
  pointer-events: none;
}

:where(fieldset[disabled]) :is(input:not([type="submit"]):not([type="button"]):not([type="reset"]), select, textarea), input:not([type="submit"]):not([type="button"]):not([type="reset"])[disabled], select[disabled], textarea[disabled] {
  --background-color: var(--form-element-disabled-background-color);
  --border-color: var(--form-element-disabled-border-color);
  opacity: var(--form-element-disabled-opacity);
  pointer-events: none;
}

:where(input, select, textarea):not([type="checkbox"]):not([type="radio"])[aria-invalid] {
  padding-left: var(--form-element-spacing-horizontal);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-right: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
  -webkit-padding-start: var(--form-element-spacing-horizontal) !important;
  -webkit-padding-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
  padding-inline-start: var(--form-element-spacing-horizontal) !important;
  padding-inline-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
}

:where(input, select, textarea):not([type="checkbox"]):not([type="radio"])[aria-invalid="false"] {
  background-image: var(--icon-valid);
}

:where(input, select, textarea):not([type="checkbox"]):not([type="radio"])[aria-invalid="true"] {
  background-image: var(--icon-invalid);
}

:where(input, select, textarea)[aria-invalid="false"] {
  --border-color: var(--form-element-valid-border-color);
}

:where(input, select, textarea)[aria-invalid="false"]:-webkit-any(:active, :focus) {
  --border-color: var(--form-element-valid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-valid-focus-color) !important;
}

:where(input, select, textarea)[aria-invalid="false"]:is(:active, :focus) {
  --border-color: var(--form-element-valid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-valid-focus-color) !important;
}

:where(input, select, textarea)[aria-invalid="true"] {
  --border-color: var(--form-element-invalid-border-color);
}

:where(input, select, textarea)[aria-invalid="true"]:-webkit-any(:active, :focus) {
  --border-color: var(--form-element-invalid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-invalid-focus-color) !important;
}

:where(input, select, textarea)[aria-invalid="true"]:is(:active, :focus) {
  --border-color: var(--form-element-invalid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-invalid-focus-color) !important;
}

[dir="rtl"] :where(input, select, textarea):not([type="checkbox"]):not([type="radio"])[aria-invalid="false"], [dir="rtl"] :where(input, select, textarea):not([type="checkbox"]):not([type="radio"])[aria-invalid="true"], [dir="rtl"] :where(input, select, textarea):not([type="checkbox"]):not([type="radio"])[aria-invalid] {
  background-position: .75rem 50%;
}

input::-webkit-input-placeholder, input::placeholder, select:invalid, textarea::-webkit-input-placeholder, textarea::placeholder {
  color: var(--form-element-placeholder-color);
  opacity: 1;
}

input:not([type="checkbox"]):not([type="radio"]), select, textarea {
  margin-bottom: var(--spacing);
}

select::-ms-expand {
  background-color: #0000;
  border: 0;
}

select:not([multiple]):not([size]) {
  padding-right: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
  padding-left: var(--form-element-spacing-horizontal);
  -webkit-padding-start: var(--form-element-spacing-horizontal);
  -webkit-padding-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
  background-image: var(--icon-chevron);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-inline-start: var(--form-element-spacing-horizontal);
  padding-inline-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
}

[dir="rtl"] select:not([multiple]):not([size]) {
  background-position: .75rem 50%;
}

:where(input, select, textarea) + small {
  width: 100%;
  margin-top: calc(var(--spacing) * -.75);
  margin-bottom: var(--spacing);
  color: var(--muted-color);
  display: block;
}

label > :where(input, select, textarea) {
  margin-top: calc(var(--spacing) * .25);
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  -webkit-margin-start: 0;
  margin-top: -.125em;
  margin-left: 0;
  margin-right: .375em;
  -webkit-margin-end: .375em;
  border-width: var(--border-width);
  font-size: inherit;
  vertical-align: middle;
  cursor: pointer;
  margin-inline-start: 0;
  margin-inline-end: .375em;
}

[type="checkbox"]::-ms-check, [type="radio"]::-ms-check {
  display: none;
}

[type="checkbox"]:checked, [type="checkbox"]:checked:active, [type="checkbox"]:checked:focus, [type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --background-color: var(--primary);
  --border-color: var(--primary);
  background-image: var(--icon-checkbox);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="checkbox"] ~ label, [type="radio"] ~ label {
  cursor: pointer;
  margin-bottom: 0;
  margin-right: .375em;
  display: inline-block;
}

[type="checkbox"]:indeterminate {
  --background-color: var(--primary);
  --border-color: var(--primary);
  background-image: var(--icon-minus);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="radio"] {
  border-radius: 50%;
}

[type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --background-color: var(--primary-inverse);
  background-image: none;
  border-width: .35em;
}

[type="checkbox"][role="switch"] {
  --background-color: var(--switch-background-color);
  --border-color: var(--switch-background-color);
  --color: var(--switch-color);
  width: 2.25em;
  height: 1.25em;
  border: var(--border-width) solid var(--border-color);
  background-color: var(--background-color);
  border-radius: 1.25em;
  line-height: 1.25em;
}

[type="checkbox"][role="switch"]:focus {
  --background-color: var(--switch-background-color);
  --border-color: var(--switch-background-color);
}

[type="checkbox"][role="switch"]:checked {
  --background-color: var(--switch-checked-background-color);
  --border-color: var(--switch-checked-background-color);
}

[type="checkbox"][role="switch"]:before {
  width: calc(1.25em - (var(--border-width) * 2) );
  height: 100%;
  background-color: var(--color);
  content: "";
  border-radius: 50%;
  transition: margin .1s ease-in-out;
  display: block;
}

[type="checkbox"][role="switch"]:checked {
  background-image: none;
}

[type="checkbox"][role="switch"]:checked:before {
  margin-left: calc(1.125em - var(--border-width) );
  -webkit-margin-start: calc(1.125em - var(--border-width) );
  margin-inline-start: calc(1.125em - var(--border-width) );
}

[type="checkbox"]:checked[aria-invalid="false"], [type="checkbox"][aria-invalid="false"], [type="checkbox"][role="switch"]:checked[aria-invalid="false"], [type="checkbox"][role="switch"][aria-invalid="false"], [type="radio"]:checked[aria-invalid="false"], [type="radio"][aria-invalid="false"] {
  --border-color: var(--form-element-valid-border-color);
}

[type="checkbox"]:checked[aria-invalid="true"], [type="checkbox"][aria-invalid="true"], [type="checkbox"][role="switch"]:checked[aria-invalid="true"], [type="checkbox"][role="switch"][aria-invalid="true"], [type="radio"]:checked[aria-invalid="true"], [type="radio"][aria-invalid="true"] {
  --border-color: var(--form-element-invalid-border-color);
}

[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

[type="color"]::-moz-focus-inner {
  padding: 0;
}

[type="color"]::-webkit-color-swatch {
  border-radius: calc(var(--border-radius) * .5);
  border: 0;
}

[type="color"]::-moz-color-swatch {
  border-radius: calc(var(--border-radius) * .5);
  border: 0;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="date"], input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="datetime-local"], input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="month"], input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="time"], input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="week"] {
  --icon-position: .75rem;
  --icon-width: 1rem;
  padding-right: calc(var(--icon-width)  + var(--icon-position) );
  background-image: var(--icon-date);
  background-position: center right var(--icon-position);
  background-size: var(--icon-width) auto;
  background-repeat: no-repeat;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="time"] {
  background-image: var(--icon-time);
}

[type="date"]::-webkit-calendar-picker-indicator, [type="datetime-local"]::-webkit-calendar-picker-indicator, [type="month"]::-webkit-calendar-picker-indicator, [type="time"]::-webkit-calendar-picker-indicator, [type="week"]::-webkit-calendar-picker-indicator {
  width: var(--icon-width);
  margin-right: calc(var(--icon-width) * -1);
  margin-left: var(--icon-position);
  opacity: 0;
}

[dir="rtl"] :-webkit-any([type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"]) {
  text-align: right;
}

[dir="rtl"] :is([type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"]) {
  text-align: right;
}

[type="file"] {
  --color: var(--muted-color);
  padding: calc(var(--form-element-spacing-vertical) * .5) 0;
  background: none;
  border: 0;
  border-radius: 0;
}

[type="file"]::-webkit-file-upload-button {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::-webkit-file-upload-button {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::file-selector-button {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::-webkit-file-upload-button:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="file"]::-webkit-file-upload-button:-webkit-any(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="file"]::file-selector-button:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="file"]::-webkit-file-upload-button {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::-webkit-file-upload-button:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="file"]::-ms-browse {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  margin-left: 0;
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  -ms-transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::-ms-browse:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 1.25rem;
  background: none;
}

[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: .25rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -webkit-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-moz-range-track {
  width: 100%;
  height: .25rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -moz-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-ms-track {
  width: 100%;
  height: .25rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -ms-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -webkit-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  margin-top: -.5rem;
}

[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -moz-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  margin-top: -.5rem;
}

[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -ms-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  margin-top: -.5rem;
}

[type="range"]:focus, [type="range"]:hover {
  --range-border-color: var(--range-active-border-color);
  --range-thumb-color: var(--range-thumb-hover-color);
}

[type="range"]:active {
  --range-thumb-color: var(--range-thumb-active-color);
}

[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.25);
}

[type="range"]:active::-moz-range-thumb {
  transform: scale(1.25);
}

[type="range"]:active::-ms-thumb {
  transform: scale(1.25);
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="search"] {
  -webkit-padding-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem);
  background-image: var(--icon-search);
  background-position: 1.125rem 50%;
  background-repeat: no-repeat;
  background-size: 1rem;
  border-radius: 5rem;
  padding-inline-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem);
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="search"][aria-invalid] {
  background-position: 1.125rem 50%, right .75rem center;
  -webkit-padding-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem) !important;
  padding-inline-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem) !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="search"][aria-invalid="false"] {
  background-image: var(--icon-search), var(--icon-valid);
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="search"][aria-invalid="true"] {
  background-image: var(--icon-search), var(--icon-invalid);
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

[dir="rtl"] :where(input):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="search"] {
  background-position: right 1.125rem center;
}

[dir="rtl"] :where(input):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"])[type="search"][aria-invalid] {
  background-position: right 1.125rem center, .75rem 50%;
}

:where(table) {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

td, th {
  padding: calc(var(--spacing) / 2) var(--spacing);
  border-bottom: var(--border-width) solid var(--table-border-color);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  text-align: left;
  text-align: start;
}

tfoot td, tfoot th {
  border-top: var(--border-width) solid var(--table-border-color);
  border-bottom: 0;
}

table[role="grid"] tbody tr:nth-child(2n+1) {
  background-color: var(--table-row-stripped-background-color);
}

code, kbd, pre, samp {
  font-size: .875em;
  font-family: var(--font-family);
}

pre {
  -ms-overflow-style: scrollbar;
  overflow: auto;
}

code, kbd, pre {
  border-radius: var(--border-radius);
  background: var(--code-background-color);
  color: var(--code-color);
  font-weight: var(--font-weight);
  line-height: initial;
}

code, kbd {
  padding: .375rem .5rem;
  display: inline-block;
}

pre {
  margin-bottom: var(--spacing);
  display: block;
  overflow-x: auto;
}

pre > code {
  padding: var(--spacing);
  font-size: 14px;
  line-height: var(--line-height);
  background: none;
  display: block;
}

code b {
  color: var(--code-tag-color);
  font-weight: var(--font-weight);
}

code i {
  color: var(--code-property-color);
  font-style: normal;
}

code u {
  color: var(--code-value-color);
  text-decoration: none;
}

code em {
  color: var(--code-comment-color);
  font-style: normal;
}

kbd {
  background-color: var(--code-kbd-background-color);
  color: var(--code-kbd-color);
  vertical-align: baseline;
}

hr {
  height: 0;
  border: 0;
  border-top: 1px solid var(--muted-border-color);
  color: inherit;
}

[hidden], template {
  display: none !important;
}

canvas {
  display: inline-block;
}

details {
  margin-bottom: var(--spacing);
  padding-bottom: var(--spacing);
  border-bottom: var(--border-width) solid var(--accordion-border-color);
  display: block;
}

details summary {
  cursor: pointer;
  transition: color var(--transition);
  line-height: 1rem;
  list-style-type: none;
}

details summary:not([role]) {
  color: var(--accordion-close-summary-color);
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::marker {
  display: none;
}

details summary::-moz-list-bullet {
  list-style-type: none;
}

details summary:after {
  width: 1rem;
  height: 1rem;
  -webkit-margin-start: calc(var(--spacing, 1rem) * .5);
  float: right;
  background-image: var(--icon-chevron);
  content: "";
  transition: transform var(--transition);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  margin-inline-start: calc(var(--spacing, 1rem) * .5);
  display: block;
  transform: rotate(-90deg);
}

details summary:focus {
  outline: 0;
}

details summary:focus:not([role="button"]) {
  color: var(--accordion-active-summary-color);
}

details summary[role="button"] {
  width: 100%;
  text-align: left;
}

details summary[role="button"]:after {
  height: calc(1rem * var(--line-height, 1.5) );
  background-image: var(--icon-chevron-button);
}

details summary[role="button"]:not(.outline).contrast:after {
  background-image: var(--icon-chevron-button-inverse);
}

details[open] > summary {
  margin-bottom: calc(var(--spacing) );
}

details[open] > summary:not([role]):not(:focus) {
  color: var(--accordion-open-summary-color);
}

details[open] > summary:after {
  transform: rotate(0);
}

[dir="rtl"] details summary {
  text-align: right;
}

[dir="rtl"] details summary:after {
  float: left;
  background-position: 0;
}

article {
  margin: var(--block-spacing-vertical) 0;
  padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
  border-radius: var(--border-radius);
  background: var(--card-background-color);
  box-shadow: var(--card-box-shadow);
}

article > footer, article > header {
  margin-right: calc(var(--block-spacing-horizontal) * -1);
  margin-left: calc(var(--block-spacing-horizontal) * -1);
  padding: calc(var(--block-spacing-vertical) * .66) var(--block-spacing-horizontal);
  background-color: var(--card-sectionning-background-color);
}

article > header {
  margin-top: calc(var(--block-spacing-vertical) * -1);
  margin-bottom: var(--block-spacing-vertical);
  border-bottom: var(--border-width) solid var(--card-border-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}

article > footer {
  margin-top: var(--block-spacing-vertical);
  margin-bottom: calc(var(--block-spacing-vertical) * -1);
  border-top: var(--border-width) solid var(--card-border-color);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

:root {
  --scrollbar-width: 0px;
}

dialog {
  z-index: 999;
  width: inherit;
  min-width: 100%;
  height: inherit;
  min-height: 100%;
  padding: var(--spacing);
  background-color: var(--modal-overlay-background-color);
  color: var(--color);
  border: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

dialog article {
  max-height: calc(100vh - var(--spacing) * 2);
  overflow: auto;
}

@media (min-width: 576px) {
  dialog article {
    max-width: 510px;
  }
}

@media (min-width: 768px) {
  dialog article {
    max-width: 700px;
  }
}

dialog article > footer, dialog article > header {
  padding: calc(var(--block-spacing-vertical) * .5) var(--block-spacing-horizontal);
}

dialog article > header .close {
  margin: 0;
  margin-left: var(--spacing);
  float: right;
}

dialog article > footer {
  text-align: right;
}

dialog article > footer [role="button"] {
  margin-bottom: 0;
}

dialog article > footer [role="button"]:not(:first-of-type) {
  margin-left: calc(var(--spacing) * .5);
}

dialog article p:last-of-type {
  margin: 0;
}

dialog article .close {
  width: 1rem;
  height: 1rem;
  margin-top: calc(var(--block-spacing-vertical) * -.5);
  margin-bottom: var(--typography-spacing-vertical);
  background-image: var(--icon-close);
  opacity: .5;
  transition: opacity var(--transition);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 1rem;
  margin-left: auto;
  display: block;
}

dialog article .close:-webkit-any([aria-current], :hover, :active, :focus) {
  opacity: 1;
}

dialog article .close:is([aria-current], :hover, :active, :focus) {
  opacity: 1;
}

dialog:not([open]), dialog[open="false"] {
  display: none;
}

.modal-is-open {
  padding-right: var(--scrollbar-width, 0);
  pointer-events: none;
  overflow: hidden;
}

.modal-is-open dialog {
  pointer-events: auto;
}

:where(.modal-is-opening, .modal-is-closing) dialog, :where(.modal-is-opening, .modal-is-closing) dialog > article {
  animation-duration: .2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

:where(.modal-is-opening, .modal-is-closing) dialog {
  animation-name: fadeIn;
  animation-duration: .8s;
}

:where(.modal-is-opening, .modal-is-closing) dialog > article {
  animation-name: slideInDown;
  animation-delay: .2s;
}

.modal-is-closing dialog, .modal-is-closing dialog > article {
  animation-direction: reverse;
  animation-delay: 0s;
}

@keyframes fadeIn {
  from {
    background-color: #0000;
  }

  to {
    background-color: var(--modal-overlay-background-color);
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

:where(nav li):before {
  float: left;
  content: "​";
}

nav, nav ul {
  display: flex;
}

nav {
  justify-content: space-between;
}

nav ol, nav ul {
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

nav ol:first-of-type, nav ul:first-of-type {
  margin-left: calc(var(--nav-element-spacing-horizontal) * -1);
}

nav ol:last-of-type, nav ul:last-of-type {
  margin-right: calc(var(--nav-element-spacing-horizontal) * -1);
}

nav li {
  padding: var(--nav-element-spacing-vertical) var(--nav-element-spacing-horizontal);
  margin: 0;
  display: inline-block;
}

nav li > * {
  --spacing: 0;
}

nav :where(a, [role="link"]) {
  margin: calc(var(--nav-link-spacing-vertical) * -1) calc(var(--nav-link-spacing-horizontal) * -1);
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
  border-radius: var(--border-radius);
  text-decoration: none;
  display: inline-block;
}

nav :where(a, [role="link"]):-webkit-any([aria-current], :hover, :active, :focus) {
  text-decoration: none;
}

nav :where(a, [role="link"]):is([aria-current], :hover, :active, :focus) {
  text-decoration: none;
}

nav [role="button"] {
  margin-right: inherit;
  margin-left: inherit;
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
}

aside li, aside nav, aside ol, aside ul {
  display: block;
}

aside li {
  padding: calc(var(--nav-element-spacing-vertical) * .5) var(--nav-element-spacing-horizontal);
}

aside li a {
  display: block;
}

aside li [role="button"] {
  margin: inherit;
}

progress {
  vertical-align: baseline;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: .5rem;
  margin-bottom: calc(var(--spacing) * .5);
  border-radius: var(--border-radius);
  background-color: var(--progress-background-color);
  color: var(--progress-color);
  border: 0;
  display: inline-block;
  overflow: hidden;
}

progress::-webkit-progress-bar {
  border-radius: var(--border-radius);
  background: none;
}

progress[value]::-webkit-progress-value {
  background-color: var(--progress-color);
}

progress::-moz-progress-bar {
  background-color: var(--progress-color);
}

@media (prefers-reduced-motion: no-preference) {
  progress:indeterminate {
    background: var(--progress-background-color) linear-gradient(to right, var(--progress-color) 30%, var(--progress-background-color) 30%) top left / 150% 150% no-repeat;
    animation: progressIndeterminate 1s linear infinite;
  }

  progress:indeterminate[value]::-webkit-progress-value {
    background-color: #0000;
  }

  progress:indeterminate::-moz-progress-bar {
    background-color: #0000;
  }
}

@media (prefers-reduced-motion: no-preference) {
  [dir="rtl"] progress:indeterminate {
    animation-direction: reverse;
  }
}

@keyframes progressIndeterminate {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

details[role="list"], li[role="list"] {
  position: relative;
}

details[role="list"] summary + ul, li[role="list"] > ul {
  z-index: 99;
  border: var(--border-width) solid var(--dropdown-border-color);
  border-radius: var(--border-radius);
  background-color: var(--dropdown-background-color);
  box-shadow: var(--card-box-shadow);
  color: var(--dropdown-color);
  white-space: nowrap;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
}

details[role="list"] summary + ul li, li[role="list"] > ul li {
  width: 100%;
  padding: calc(var(--form-element-spacing-vertical) * .5) var(--form-element-spacing-horizontal);
  margin-bottom: 0;
  list-style: none;
}

details[role="list"] summary + ul li:first-of-type, li[role="list"] > ul li:first-of-type {
  margin-top: calc(var(--form-element-spacing-vertical) * .5);
}

details[role="list"] summary + ul li:last-of-type, li[role="list"] > ul li:last-of-type {
  margin-bottom: calc(var(--form-element-spacing-vertical) * .5);
}

details[role="list"] summary + ul li a, li[role="list"] > ul li a {
  margin: calc(var(--form-element-spacing-vertical) * -.5) calc(var(--form-element-spacing-horizontal) * -1);
  padding: calc(var(--form-element-spacing-vertical) * .5) var(--form-element-spacing-horizontal);
  color: var(--dropdown-color);
  text-overflow: ellipsis;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

details[role="list"] summary + ul li a:hover, li[role="list"] > ul li a:hover {
  background-color: var(--dropdown-hover-background-color);
}

details[role="list"] summary:after, li[role="list"] > a:after {
  width: 1rem;
  height: calc(1rem * var(--line-height, 1.5) );
  -webkit-margin-start: .5rem;
  float: right;
  transform: rotate(0);
  content: "";
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  margin-inline-start: .5rem;
  display: block;
}

details[role="list"] {
  border-bottom: none;
  padding: 0;
}

details[role="list"] summary {
  margin-bottom: 0;
}

details[role="list"] summary:not([role]) {
  height: calc(1rem * var(--line-height)  + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--form-element-border-color);
  border-radius: var(--border-radius);
  background-color: var(--form-element-background-color);
  color: var(--form-element-placeholder-color);
  line-height: inherit;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
}

details[role="list"] summary:not([role]):active, details[role="list"] summary:not([role]):focus {
  border-color: var(--form-element-active-border-color);
  background-color: var(--form-element-active-background-color);
}

details[role="list"] summary:not([role]):focus {
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

details[role="list"][open] summary {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

details[role="list"][open] summary:before {
  z-index: 1;
  content: "";
  cursor: default;
  background: none;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

nav details[role="list"] summary, nav li[role="list"] a {
  direction: ltr;
  display: flex;
}

nav details[role="list"] summary + ul, nav li[role="list"] > ul {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  border-radius: var(--border-radius);
}

nav details[role="list"] summary + ul li a, nav li[role="list"] > ul li a {
  border-radius: 0;
}

nav details[role="list"] summary, nav details[role="list"] summary:not([role]) {
  height: auto;
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
}

nav details[role="list"][open] summary {
  border-radius: var(--border-radius);
}

nav details[role="list"] summary + ul {
  margin-top: var(--outline-width);
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}

nav details[role="list"] summary[role="link"] {
  margin-bottom: calc(var(--nav-link-spacing-vertical) * -1);
  line-height: var(--line-height);
}

nav details[role="list"] summary[role="link"] + ul {
  margin-top: calc(var(--nav-link-spacing-vertical)  + var(--outline-width) );
  -webkit-margin-start: calc(var(--nav-link-spacing-horizontal) * -1);
  margin-inline-start: calc(var(--nav-link-spacing-horizontal) * -1);
}

li[role="list"] a:active ~ ul, li[role="list"] a:focus ~ ul, li[role="list"]:hover > ul {
  display: flex;
}

li[role="list"] > ul {
  margin-top: calc(var(--nav-link-spacing-vertical)  + var(--outline-width) );
  -webkit-margin-start: calc(var(--nav-element-spacing-horizontal)  - var(--nav-link-spacing-horizontal) );
  margin-inline-start: calc(var(--nav-element-spacing-horizontal)  - var(--nav-link-spacing-horizontal) );
  display: none;
}

li[role="list"] > a:after {
  background-image: var(--icon-chevron);
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-busy="true"]:not(input):not(select):not(textarea):before {
  width: 1em;
  height: 1em;
  content: "";
  vertical-align: text-bottom;
  vertical-align: -.125em;
  opacity: var(--loading-spinner-opacity);
  border: .1875em solid;
  border-right-color: #0000;
  border-radius: 1em;
  animation: spinner .75s linear infinite;
  display: inline-block;
}

[aria-busy="true"]:not(input):not(select):not(textarea):not(:empty):before {
  margin-right: calc(var(--spacing) * .5);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) * .5);
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) * .5);
}

[aria-busy="true"]:not(input):not(select):not(textarea):empty {
  text-align: center;
}

a[aria-busy="true"], button[aria-busy="true"], input[type="button"][aria-busy="true"], input[type="reset"][aria-busy="true"], input[type="submit"][aria-busy="true"] {
  pointer-events: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:not(a):not(button):not(input) {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

[data-tooltip]:after, [data-tooltip]:before {
  z-index: 99;
  border-radius: var(--border-radius);
  background: var(--tooltip-background-color);
  content: attr(data-tooltip);
  color: var(--tooltip-color);
  font-style: normal;
  font-weight: var(--font-weight);
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  padding: .25rem .5rem;
  font-size: .875rem;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -.25rem);
}

[data-tooltip]:after {
  content: "";
  color: var(--tooltip-background-color);
  background-color: #0000;
  border-top: .3rem solid;
  border-left: .3rem solid #0000;
  border-right: .3rem solid #0000;
  border-radius: 0;
  padding: 0;
  transform: translate(-50%);
}

[data-tooltip]:focus:after, [data-tooltip]:focus:before, [data-tooltip]:hover:after, [data-tooltip]:hover:before {
  opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
  [data-tooltip]:focus:after, [data-tooltip]:focus:before, [data-tooltip]:hover:after, [data-tooltip]:hover:before {
    animation-name: slide;
    animation-duration: .2s;
  }

  [data-tooltip]:focus:after, [data-tooltip]:hover:after {
    animation-name: slideCaret;
  }
}

@keyframes slide {
  from {
    opacity: 0;
    transform: translate(-50%, .75rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -.25rem);
  }
}

@keyframes slideCaret {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(-50%, -.25rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%);
  }
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"], [disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

[tabindex], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
}

[dir="rtl"] {
  direction: rtl;
}

@media (prefers-reduced-motion: reduce) {
  :not([aria-busy="true"]), :not([aria-busy="true"]):after, :not([aria-busy="true"]):before {
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    animation-delay: -1ms !important;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  padding: 4vmin;
  display: flex;
  position: absolute;
  transform: translate(0%, -100%);
}

.overlay, .overlay * {
  color: #fff;
  font-family: Edit Undo;
  font-size: 3.5vmin;
  font-weight: normal;
}

.overlay[aria-busy="true"]:before, .overlay tbody[aria-busy="true"]:before {
  width: 20vmin;
  height: 20vmin;
  opacity: .5;
  border-width: 3vmin;
  border-radius: 10vmin;
  align-self: center;
}

.overlay tbody[aria-busy="true"]:before {
  margin-top: 10vmin;
  display: block;
  position: absolute;
  left: calc(50% - 10vmin);
}

.overlay tbody[aria-busy="true"] tr {
  opacity: .3;
}

.overlay[aria-busy="true"] *, .overlay[aria-hidden="true"] {
  display: none !important;
}

.overlay main {
  flex-grow: 1;
  padding-top: 18%;
}

.overlay footer {
  padding: 0 !important;
}

#gameover-overlay main {
  padding-top: 50%;
}

.overlay h1, .overlay h1 *, .overlay h2, .overlay h2 *, .overlay h3, .overlay h3 * {
  filter: url("#crispify");
  margin: 0;
}

.overlay h1 *, .overlay h2 *, .overlay h3 * {
  font-size: inherit !important;
}

.overlay h1 {
  font-size: 7vmin;
}

.overlay h2 {
  font-size: 3.5vmin;
  color: #a0a0a0 !important;
}

.overlay #rank-global, .overlay #rank-personal {
  color: #c20c0c !important;
}

.overlay form {
  margin: 0;
}

.overlay fieldset {
  text-align: left;
  margin-bottom: 5vmin !important;
}

.overlay legend {
  font-size: 4.5vmin;
  color: #a0a0a0 !important;
}

.overlay label[for$="-text"] {
  display: none;
}

.overlay label[for$="-radio"] {
  width: 32%;
  display: inline-block;
}

.overlay button, .overlay input {
  pointer-events: auto;
  background-color: #20202066;
  border: .5vmin solid #fff;
  border-radius: 0;
}

.overlay button {
  background-color: #80808066;
}

.overlay button.active {
  background-color: gray;
}

.overlay button, .overlay input[type="text"] {
  margin: 0;
  height: -webkit-fit-content !important;
  height: fit-content !important;
  padding: 1vmin !important;
}

.overlay input[type="text"] {
  background-size: 5vmin !important;
  margin-bottom: 2vmin !important;
  padding-left: 1.5vmin !important;
}

.overlay input[type="radio"]:checked, .overlay input[type="radio"]:checked:active, .overlay input[type="radio"]:checked:focus {
  background-color: #fff;
}

.overlay input[type="range"] {
  --range-thumb-color: #fff;
  height: 2vmin;
  border: .5vmin solid #ffffff64;
}

.overlay input[type="range"]:focus {
  border: .5vmin solid #fffb;
}

.overlay input[type="range"]::-moz-range-track {
  background-color: #0000;
}

.overlay input[type="range"]::-webkit-slider-runnable-track {
  background-color: #0000;
}

.overlay input[type="range"]::-ms-track {
  background-color: #0000;
}

.overlay input[type="range"]::-moz-range-thumb {
  width: 4.2vmin;
  height: 4.2vmin;
  border: .5vmin solid;
  border-radius: 0;
  margin-top: -1.9vmin;
}

.overlay input[type="range"]::-webkit-slider-thumb {
  width: 4.2vmin;
  height: 4.2vmin;
  border: .4vmin solid;
  border-radius: 0;
  margin-top: -1.9vmin;
}

.overlay input[type="range"]::-ms-thumb {
  width: 4.2vmin;
  height: 4.2vmin;
  border: .4vmin solid;
  border-radius: 0;
  margin-top: -1.9vmin;
}

.overlay .button-group {
  gap: .5vmin;
  display: flex;
}

.overlay .button-group button {
  font-size: 2.5vmin;
}

.overlay .leaderboard {
  margin-bottom: 0;
}

.overlay .leaderboard thead {
  border-bottom: .4vmin solid #fff;
}

.overlay .leaderboard thead, .overlay .leaderboard tr {
  width: 100%;
  display: table;
}

.overlay .leaderboard tbody {
  pointer-events: auto;
  display: block;
  overflow-y: scroll;
  max-height: 40.9vmin !important;
}

.overlay .leaderboard tr.rank {
  background-color: #c20c0c;
}

.overlay .leaderboard th, .overlay .leaderboard td {
  border: none;
  padding: .2vmin;
}

.overlay .leaderboard th:nth-of-type(1) {
  width: 10%;
}

.overlay .leaderboard th:nth-of-type(2), .overlay .leaderboard td:nth-of-type(1) {
  width: 20%;
}

.overlay .leaderboard th:nth-of-type(3), .overlay .leaderboard td:nth-of-type(2) {
  width: 60%;
}

.bounce {
  position: relative;
}

.bounce:before {
  content: "";
  transform-origin: 0;
  background: #ffffff0a;
  transition-duration: .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
}

.bounce:hover:before, .bounce:focus:before, .bounce:active:before {
  transition-timing-function: cubic-bezier(.52, 1.64, .37, .66);
  transform: scaleX(1);
}

@font-face {
  font-family: Edit Undo;
  src: url("/fonts/EditUndo.woff2") format("woff2"), url("/fonts/EditUndo.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --border-radius: .2rem;
}

#app {
  max-width: 100vmin;
  max-height: 100vmin;
  text-align: center;
  position: relative;
  margin-bottom: 2rem !important;
  padding: 0 !important;
}

#app canvas {
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
  box-shadow: var(--card-box-shadow);
}

#app #stats {
  left: inherit !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

#manual {
  color: var(--muted-color);
  text-align: center;
}

#manual h4 {
  color: var(--muted-color);
  margin-bottom: 1rem;
  font-size: 1rem;
}

#manual ul {
  padding-left: 0;
}

#manual li {
  color: var(--muted-color);
  margin-bottom: .25rem;
  font-size: medium;
  line-height: 2;
  list-style: none !important;
}

#manual kbd {
  padding: .15rem .25rem;
}

footer {
  text-align: center;
  padding: 2rem 0 !important;
}

footer ul {
  padding-left: 0;
}

footer li {
  color: var(--muted-color);
  margin-bottom: .25rem;
  font-size: small;
  list-style: none !important;
}

footer a {
  color: var(--muted-color);
}

footer .github {
  margin-top: 3rem;
}

/*# sourceMappingURL=index.eb45dde9.css.map */
