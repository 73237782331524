.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(0%, -100%);

  padding: 4vmin;

  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay,
.overlay * {
  color: #ffffff;
  font-family: 'Edit Undo';
  font-weight: normal;
  font-size: 3.5vmin;
}

.overlay[aria-busy='true']::before,
.overlay tbody[aria-busy='true']::before {
  align-self: center;
  border-width: 3vmin;
  border-radius: 10vmin;
  width: 20vmin;
  height: 20vmin;
  opacity: 0.5;
}
.overlay tbody[aria-busy='true']::before {
  position: absolute;
  display: block;
  margin-top: 10vmin;
  left: calc(50% - 10vmin);
}
.overlay tbody[aria-busy='true'] tr {
  opacity: 0.3;
}

.overlay[aria-busy='true'] * {
  display: none !important;
}

.overlay[aria-hidden='true'] {
  display: none !important;
}

.overlay main {
  flex-grow: 1;
  padding-top: 18%;
}

.overlay footer {
  padding: 0 !important;
}

#gameover-overlay main {
  padding-top: 50%;
}

/*
 * Text
 */

.overlay h1,
.overlay h1 *,
.overlay h2,
.overlay h2 *,
.overlay h3,
.overlay h3 * {
  margin: 0;
  filter: url(#crispify);
}

.overlay h1 *,
.overlay h2 *,
.overlay h3 * {
  font-size: inherit !important;
}

.overlay h1 {
  font-size: 7vmin;
}

.overlay h2 {
  font-size: 3.5vmin;
  color: #a0a0a0 !important;
}

.overlay #rank-global,
.overlay #rank-personal {
  color: #c20c0c !important;
}

/*
 * Form
 */

.overlay form {
  margin: 0;
}

.overlay fieldset {
  text-align: left;
  margin-bottom: 5vmin !important;
}

.overlay legend {
  font-size: 4.5vmin;
  color: #a0a0a0 !important;
}

.overlay label[for$='-text'] {
  display: none;
}

.overlay label[for$='-radio'] {
  display: inline-block;
  width: 32%;
}

.overlay button,
.overlay input {
  background-color: rgba(32, 32, 32, 0.4);
  border: 0.5vmin solid #ffffff;
  border-radius: 0;
  pointer-events: auto;
}

.overlay button {
  background-color: rgba(128, 128, 128, 0.4);
}

.overlay button.active {
  background-color: rgba(128, 128, 128);
}

.overlay button,
.overlay input[type='text'] {
  height: fit-content !important;
  margin: 0;
  padding: 1vmin !important;
}

.overlay input[type='text'] {
  background-size: 5vmin !important;
  margin-bottom: 2vmin !important;
  padding-left: 1.5vmin !important;
}

.overlay input[type='radio']:checked,
.overlay input[type='radio']:checked:active,
.overlay input[type='radio']:checked:focus {
  background-color: #ffffff;
}

.overlay input[type='range'] {
  --range-thumb-color: #ffffff;
  border: 0.5vmin solid #ffffff64;
  height: 2vmin;
}

.overlay input[type='range']:focus {
  border: 0.5vmin solid #ffffffbb;
}

.overlay input[type='range']::-moz-range-track {
  background-color: transparent;
}

.overlay input[type='range']::-webkit-slider-runnable-track {
  background-color: transparent;
}

.overlay input[type='range']::-ms-track {
  background-color: transparent;
}

.overlay input[type='range']::-moz-range-thumb {
  border: 0.5vmin solid;
  border-radius: 0;
  width: 4.2vmin;
  height: 4.2vmin;
  margin-top: -1.9vmin;
}

.overlay input[type='range']::-webkit-slider-thumb {
  border: 0.4vmin solid;
  border-radius: 0;
  width: 4.2vmin;
  height: 4.2vmin;
  margin-top: -1.9vmin;
}

.overlay input[type='range']::-ms-thumb {
  border: 0.4vmin solid;
  border-radius: 0;
  width: 4.2vmin;
  height: 4.2vmin;
  margin-top: -1.9vmin;
}

/*
 * Leaderboard
 */

.overlay .button-group {
  display: flex;
  gap: 0.5vmin;
}

.overlay .button-group button {
  font-size: 2.5vmin;
}

.overlay .leaderboard {
  margin-bottom: 0;
}

.overlay .leaderboard thead {
  border-bottom: 0.4vmin solid #ffffff;
}

.overlay .leaderboard thead,
.overlay .leaderboard tr {
  display: table;
  width: 100%;
}

.overlay .leaderboard tbody {
  display: block;
  max-height: 40.9vmin !important;
  overflow-y: scroll;
  pointer-events: auto;
}

.overlay .leaderboard tr.rank {
  background-color: #c20c0c;
}

.overlay .leaderboard th,
.overlay .leaderboard td {
  border: none;
  padding: 0.2vmin;
}

.overlay .leaderboard th:nth-of-type(1) {
  width: 10%;
}
.overlay .leaderboard th:nth-of-type(2),
.overlay .leaderboard td:nth-of-type(1) {
  width: 20%;
}
.overlay .leaderboard th:nth-of-type(3),
.overlay .leaderboard td:nth-of-type(2) {
  width: 60%;
}

/*
 * Effects
 */

.bounce {
  position: relative;
}
.bounce:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff0a;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-duration: 0.3s;
}
.bounce:hover:before,
.bounce:focus:before,
.bounce:active:before {
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
