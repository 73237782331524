@import 'npm:@picocss/pico/css/pico.min.css';
@import './overlay.css';

@font-face {
  font-family: 'Edit Undo';
  src: url('/fonts/EditUndo.woff2') format('woff2'), url('/fonts/EditUndo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --border-radius: 0.2rem;
}

#app {
  max-width: 100vmin;
  max-height: 100vmin;
  padding: 0rem !important;
  margin-bottom: 2rem !important;
  position: relative;
  text-align: center;
}

#app canvas {
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
  box-shadow: var(--card-box-shadow);
}

#app #stats {
  position: absolute !important;
  left: inherit !important;
  top: 0 !important;
  right: 0 !important;
}

#manual {
  color: var(--muted-color);
  text-align: center;
}

#manual h4 {
  font-size: 1rem;
  color: var(--muted-color);
  margin-bottom: 1rem;
}

#manual ul {
  padding-left: 0;
}

#manual li {
  color: var(--muted-color);
  font-size: medium;
  line-height: 2;
  list-style: none !important;
  margin-bottom: 0.25rem;
}

#manual kbd {
  padding: 0.15rem 0.25rem;
}

footer {
  text-align: center;
  padding: 2rem 0 !important;
}

footer ul {
  padding-left: 0;
}

footer li {
  color: var(--muted-color);
  font-size: small;
  list-style: none !important;
  margin-bottom: 0.25rem;
}

footer a {
  color: var(--muted-color);
}

footer .github {
  margin-top: 3rem;
}
